import dayjs from 'dayjs';
import { post } from '@/utils/api';
import { listStatusOrder, getStatusOrder } from '@/utils/agency/marketer';

export default {
  state: () => ({
    loadingOrderData: false,
    orderData: {
      allNumber: 0,
      totalPrice: 0,
      totalDiscount: 0,
      totalPaid: 0,
      orders: [],
    },
    listStatusOrder: [
      { key: 'ok', label: 'OK' },
      { key: 'debit', label: 'Debit' },
      { key: 'waiting', label: 'Waiting' },
      { key: 'cancelled', label: 'Cancelled' },
    ],
  }),
  getters: {
    GET_STATE_FETCHING_ORDER_DATA: (state) => state.loadingOrderData,
    GET_ALL_NUMBER: (state) => state.orderData?.allNumber ?? 0,
    GET_TOTAL_PRICE: (state) => state.orderData?.totalPrice ?? 0,
    GET_TOTAL_DISCOUNT: (state) => state.orderData?.totalDiscount ?? 0,
    GET_TOTAL_PAID: (state) => state.orderData?.totalPaid ?? 0,
    GET_LIST_ORDER: (state) => state.orderData?.orders ?? [],
    GET_LIST_FIELDS_FULL: () => [
      { key: 'date', label: 'Booking Date', sortable: true },
      { key: 'pnr', label: 'PNR', sortable: true },
      { key: 'name', label: 'Client Name', sortable: true },
      { key: 'hotel', label: 'Hotel Name', sortable: true },
      { key: 'checkIn', label: 'CheckIn Date', sortable: true },
      { key: 'checkOut', label: 'CheckOut Date', sortable: true },
      { key: 'fullPrice', label: 'Full Price', sortable: true },
      { key: 'discount', label: 'Discount', sortable: true },
      { key: 'paidPrice', label: 'Paid Price', sortable: true },
      { key: 'status', label: 'Status Order', sortable: false },
    ],
    GET_LIST_FIELDS_ELLIPSIS: () => [
      { key: 'date', label: 'Booking Date', sortable: true },
      { key: 'pnr', label: 'PNR', sortable: true },
      { key: 'name', label: 'Client Name', sortable: true },
      { key: 'hotel', label: 'Hotel Name', sortable: true },
      { key: 'paidPrice', label: 'Paid Price', sortable: true },
      { key: 'status', label: 'Status Order', sortable: false },
    ],
    GET_LIST_STATUS_ORDER: () => listStatusOrder,
    GET_STATUS_LABEL_ORDER: () => (pOpt) => listStatusOrder.find((el) => el.key === pOpt)?.label ?? pOpt,
  },
  mutations: {
    SET_ORDER_DATA: (state, payload) => { state.orderData = payload; },
    SET_STATE_FETCHING_ORDER_DATA: (state, payload) => { state.loadingOrderData = payload; },
  },
  actions: {
    FETCH_ORDER_HISTORY_MARKETER: async (context, payload) => {
      console.log(payload);
      context.commit('SET_STATE_FETCHING_ORDER_DATA', true);
      try {
        // setTimeout(() => {
        //   context.commit('SET_ORDER_DATA', {
        //     allNumber: 10,
        //     totalPrice: 10000,
        //     totalDiscount: 100,
        //     totalPaid: 8000,
        //     orders: [
        //       { date: '2023-09-20', pnr: 12345, name: 'test', hotel: 'test hotel', checkIn: '2023-09-25', checkOut: '2023-09-30', fullPrice: 100, discount: 5, paidPrice: 80, status: 'waiting' },
        //       { date: '2023-09-20', pnr: 12345, name: 'test', hotel: 'test hotel', checkIn: '2023-09-25', checkOut: '2023-09-30', fullPrice: 100, discount: 5, paidPrice: 80, status: 'ok' },
        //       { date: '2023-09-20', pnr: 12345, name: 'test', hotel: 'test hotel', checkIn: '2023-09-25', checkOut: '2023-09-30', fullPrice: 100, discount: 5, paidPrice: 80, status: 'debit' },
        //       { date: '2023-09-20', pnr: 12345, name: 'test', hotel: 'test hotel', checkIn: '2023-09-25', checkOut: '2023-09-30', fullPrice: 100, discount: 5, paidPrice: 80, status: 'waiting' },
        //       { date: '2023-09-20', pnr: 12345, name: 'test', hotel: 'test hotel', checkIn: '2023-09-25', checkOut: '2023-09-30', fullPrice: 100, discount: 5, paidPrice: 80, status: 'cancelled' },
        //       { date: '2023-09-20', pnr: 12345, name: 'test', hotel: 'test hotel', checkIn: '2023-09-25', checkOut: '2023-09-30', fullPrice: 100, discount: 5, paidPrice: 80, status: 'waiting' },
        //     ],
        //   });
        //   context.commit('SET_STATE_FETCHING_ORDER_DATA', false);
        // }, 2000);
        const res = await post('DOMESTIC', '/analysis/bookings/searchByAgency', payload, context);
        const data = await res.data.data;
        console.log(data);
        let totalPrice = 0, totalPaid = 0;
        const orders = [];
        data.forEach((order) => {
          totalPrice += Math.round(order.originalAmount);
          totalPaid += Math.round(order.payedAmount);
          orders.push({
            date: dayjs(order.bookData).format('YYYY-MM-DD'),
            pnr: order.pnr,
            name: order.payerName,
            hotel: order.hotelName,
            checkIn: dayjs(order.checkinDate).format('YYYY-MM-DD'),
            checkOut: dayjs(order.checkoutDate).format('YYYY-MM-DD'),
            fullPrice: order.originalAmount,
            discount: order.equivalentDiscount,
            paidPrice: order.payedAmount,
            status: getStatusOrder(order.confirmedPnrStatus.pnrStatus),
          });
        });
        const statsInfo = {
          allNumber: res.data.total,
          totalPrice,
          totalPaid,
          totalDiscount: Math.round(totalPrice - totalPaid),
          orders,
        };
        console.log(statsInfo);
        context.commit('SET_ORDER_DATA', statsInfo);
        context.commit('SET_STATE_FETCHING_ORDER_DATA', false);
      } catch (e) {
        console.log(e);
        context.commit('SET_STATE_FETCHING_ORDER_DATA', false);
      }
    },
  },
};
