const listStatusOrder = [
  { key: 'ok', label: 'OK' },
  { key: 'debit', label: 'Debit' },
  { key: 'waiting', label: 'Waiting' },
  { key: 'cancelled', label: 'Cancelled' },
];

const getStatusOrder = (pStatus) => {
  let status = '';
  switch (pStatus.toLowerCase()) {
    case 'ok':
      status = 'ok';
      break;
    case 'debit':
      status = 'debit';
      break;
    case 'waiting':
      status = 'waiting';
      break;
    case 'cancelled': case 'canceled':
      status = 'cancelled';
      break;
    default:
      status = pStatus;
  }
  return status;
};

export {
  listStatusOrder,
  getStatusOrder,
};
