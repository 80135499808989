const { VUE_APP_SOCKET_ENDPOINT, VUE_APP_STAGING_SOCKET_ENDPOINT } = process.env;

const getSocketType = (event) => {
  const { url } = event.currentTarget;
  let type = '';
  if (url.includes('agencyMessage') && url.includes('shared=true')) {
    type = 'shared';
  } else if (url.includes('agencyMessage')) {
    type = 'private';
  } else {
    type = '';
  }
  return type;
};

export default {
  state: () => ({
    socket: null,
    isConnected: false,
    privateMessage: '',
    sharedMessage: '',
    reconnectError: null,

    callerInfo: null,
    socketErrorState: null,
  }),
  getters: {
    IS_SOCKET_CONNECTED: (state) => state.isConnected,
    GET_PRIVATE_MESSAGE: (state) => state.privateMessage,
    GET_SHARED_MESSAGE: (state) => state.sharedMessage,
    GET_SOCKET_ERROR_STATE: (state) => state.socketErrorState,
  },
  mutations: {
    SOCKET_ONOPEN(state, event) {
      const socket = state.socket || {};
      const type = getSocketType(event);
      socket[type] = event.currentTarget;

      state.socket = socket;
      state.isConnected = true;
    },
    SOCKET_ONCLOSE(state) {
      state.isConnected = false;
      const title = 'Socket has closed', msg = 'Socket has closed';
      state.socketErrorState = {
        title,
        message: msg,
      };
    },
    SOCKET_ONERROR(state, event) {
      const msg = JSON.stringify(event);
      state.socketErrorState = {
        title: 'Socket has an error',
        message: msg,
      };
    },
    SOCKET_ONMESSAGE(state, event) {
      const type = getSocketType(event);
      const parsingData = event.data ? JSON.parse(event.data) : null;

      if (type === 'private' && parsingData?.type === 'agentMessage') {
        state.privateMessage = parsingData?.data || '';
      } else if (type === 'shared' && parsingData?.type === 'agentMessage') {
        state.sharedMessage = parsingData?.data || '';
      } else if (type === 'private' && parsingData?.type === 'callerInfo') {
        state.callerInfo = parsingData?.data;
      }
    },
    SOCKET_RECONNECT(state, count) {
      console.info(count);
      const msg = `The reconnection count:${count}`;
      state.socketErrorState = {
        title: 'Socket is reconnecting',
        message: msg,
      };
    },
    SOCKET_RECONNECT_ERROR(state, event) {
      const reconnectError = state.reconnectError || {};
      const type = getSocketType(event);
      reconnectError[type] = true;

      state.reconnectError = reconnectError;
      const title = 'Socket reconnecting has an error', msg = JSON.stringify(event);
      state.socketErrorState = {
        title,
        message: msg,
      };
    },
  },
  actions: {
    SEND_MESSAGE_VIA_SOCKET: (context, message) => {
      const { socket } = context.state;
      if (socket) { socket.send(message); }
    },
    SOCKET_PRIVATE_CONNECT: (context, payload) => {
      const vm = payload;
      const { userAgencySid } = context.rootState.agencyUsers;
      const { agencyCode } = context.rootState.whiteLabel;
      const socketHost = context.rootState.isStaging ? VUE_APP_STAGING_SOCKET_ENDPOINT : VUE_APP_SOCKET_ENDPOINT;
      vm.$connect(`${socketHost}/api/agencyMessage/agentWs/?agency=${agencyCode}&agencySid=${userAgencySid}`);
    },
    SOCKET_SHARED_CONNECT: (context, payload) => {
      const vm = payload;
      const { userAgencySid } = context.rootState.agencyUsers;
      const { agencyCode } = context.rootState.whiteLabel;
      const socketHost = context.rootState.isStaging ? VUE_APP_STAGING_SOCKET_ENDPOINT : VUE_APP_SOCKET_ENDPOINT;
      vm.$connect(`${socketHost}/api/agencyMessage/agentWs/?shared=true&agency=${agencyCode}&agencySid=${userAgencySid}`);
    },
    SOCKET_DIS_CONNECT: (context, payload) => {
      const vm = payload;
      vm.$disconnect();
      const { socket } = context.state;
      socket.private.close();
      socket.shared.close();
    },
  },
};
