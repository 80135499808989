import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/home/homepage/Home'),
      meta: { title: 'Home' },
    },
    {
      path: '/ga-test',
      name: 'ga-test',
      component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/booking/TestGA'),
      meta: { title: 'Home' },
    },
    {
      path: '/search-result',
      name: 'search-result',
      component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/searchResult/searchResultThemes/SearchResult'),
      meta: { title: 'Search Result' },
    },
    {
      path: '/product-page',
      name: 'product-page',
      component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/productPage/product/ProductPage'),
      meta: { title: 'Product Page' },
    },
    // {
    //   path: '/dashboard',
    //   name: 'dashboard-marketer',
    //   component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/bookingInfoList/DashboardMarketer'),
    // },
    {
      path: '/external-booking',
      name: 'external-booking',
      component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/pendingBookingPage/PendingInfo'),
    },
    {
      path: '/cancel-booking',
      name: 'cancel-booking',
      component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/cancelBooking/CancelBooking'),
    },
    {
      path: '/personal-area',
      name: 'personal-area',
      component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/bookingInfoList/BookingInfo'),
    },
    {
      path: '/hotels-page',
      name: 'hotels-page',
      component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/hotelPages/HotelsPage'),
      meta: { title: 'Destination Page' },
    },
    {
      path: '/hotel-info',
      name: 'hotel-info',
      component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/hotelPages/HotelInfo'),
      // meta: { title: 'Hotel Info' },
    },
    {
      path: '/hotel-chain',
      name: 'hotel-chain',
      component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/hotelPages/HotelChain'),
      meta: { title: 'Hotel Chain' },
    },
    {
      path: '/booking',
      name: 'booking',
      component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/Booking'),
      meta: { title: 'Booking Page' },
      children: [
        {
          path: 'hotel',
          component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/BookingHotel'),
          name: 'booking-hotel',
          meta: { title: 'Booking Hotel' },
        },
        {
          path: 'verifying-card',
          component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/VerifyingCard'),
          name: 'verifying-card',
          meta: { title: 'Verifying Card' },
        },
        {
          path: 'card-verified',
          component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/CardVerified'),
          name: 'card-verified',
          meta: { title: 'Card Verified' },
        },
        {
          path: 'flight',
          component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/Flight'),
          name: 'booking-flight',
          meta: { title: 'Booking Flight' },
        },
      ],
    },
    {
      path: '/redirect-verified',
      component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/Redirect'),
      name: 'redirect-verified',
      meta: { title: 'Card Verified' },
    },
    {
      path: '/fail-card-verified',
      component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/Redirect'),
      name: 'fail-card-verified',
      meta: { title: 'Card Verified' },
    },
    {
      path: '/sitemap',
      name: 'sitemap',
      component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/sitemap'),
      meta: { title: 'Site Map' },
    },
    {
      path: '/error-500',
      name: '404',
      component: () => import(/* webpackChunkName: "error-chunk" */ '@/components/404'),
      meta: { title: '500' },
    },
    {
      path: '/:slug',
      name: 'slug-info',
      component: () => import(/* webpackChunkName: "error-chunk" */ '@/components/TravelInfoPage/TravelInfo'),
      // meta: { title: 'info' },
      meta: {
        title: 'info',
        sitemap: {
          slugs: [
            '%D7%A6%D7%95%D7%A8_%D7%A7%D7%A9%D7%A8',
            '%D7%94%D7%A8%D7%A9%D7%9E%D7%94_%D7%9C%D7%A8%D7%A9%D7%99%D7%9E%D7%AA_%D7%AA%D7%A4%D7%95%D7%A6%D7%94',
            '%D7%91%D7%A7%D7%A9%D7%94_%D7%9C%D7%91%D7%99%D7%98%D7%95%D7%9C_%D7%94%D7%96%D7%9E%D7%A0%D7%94',
            '%D7%9E%D7%93%D7%A8%D7%99%D7%9A_%D7%94%D7%99%D7%A2%D7%93%D7%99%D7%9D',
            'D7%90%D7%95%D7%93%D7%95%D7%AA_',
            '%D7%9E%D7%93%D7%99%D7%A0%D7%99%D7%95%D7%AA_%D7%A4%D7%A8%D7%98%D7%99%D7%95%D7%AA',
            '%D7%9E%D7%99%D7%93%D7%A2_%D7%9C%D7%A0%D7%95%D7%A1%D7%A2',
            '%D7%AA%D7%95%D7%91%D7%A2%D7%A0%D7%95%D7%AA_%D7%99%D7%99%D7%A6%D7%95%D7%92%D7%99%D7%AA',
            '%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_%D7%91%D7%99%D7%98%D7%95%D7%9C_%D7%95%D7%96%D7%99%D7%9B%D7%95%D7%99',
            '%D7%97%D7%95%D7%A7_%D7%A9%D7%A8%D7%95%D7%AA%D7%99_%D7%94%D7%AA%D7%A2%D7%95%D7%A4%D7%94',
            '%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_%D7%9B%D7%A8%D7%98%D7%99%D7%A1%D7%99_%D7%98%D7%99%D7%A1%D7%94',
            '%D7%AA%D7%A0%D7%90%D7%99%D7%9D_%D7%95%D7%90%D7%97%D7%A8%D7%99%D7%95%D7%AA_%D7%91%D7%98%D7%99%D7%95%D7%9C%D7%99%D7%9D_%D7%9E%D7%90%D7%95%D7%A8%D7%92%D7%A0%D7%99%D7%9D',
            '%D7%AA%D7%A0%D7%90%D7%99%D7%9D_%D7%9B%D7%9C%D7%9C%D7%99%D7%9D_%D7%95%D7%94%D7%92%D7%91%D7%9C%D7%AA_%D7%90%D7%97%D7%A8%D7%99%D7%95%D7%AA',
            '%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_FLYING_SAFE',
            '%D7%94%D7%A0%D7%97%D7%99%D7%95%D7%AA_%D7%9C%D7%99%D7%95%D7%A6%D7%90%D7%99%D7%9D_%D7%9E%D7%99%D7%A9%D7%A8%D7%90%D7%9C_%D7%95%D7%9C%D7%97%D7%95%D7%96%D7%A8%D7%99%D7%9D_%D7%9C%D7%99%D7%A9%D7%A8%D7%90%D7%9C',
            '%D7%91%D7%98%D7%95%D7%9E%D7%99_%D7%A7%D7%A0%D7%99%D7%95%D7%AA_%D7%91%D7%91%D7%98%D7%95%D7%9E%D7%99_%D7%94%D7%A9%D7%98%D7%99%D7%97_%D7%94%D7%9E%D7%A2%D7%95%D7%A4%D7%A3',
            '%D7%93%D7%99%D7%9C%D7%99%D7%9D_%D7%9C%D7%91%D7%98%D7%95%D7%9E%D7%99',
            '%D7%97%D7%91%D7%99%D7%9C%D7%95%D7%AA_%D7%A0%D7%95%D7%A4%D7%A9_%D7%9C%D7%90%D7%A0%D7%98%D7%9C%D7%99%D7%94',
            '%D7%98%D7%99%D7%95%D7%9C%D7%99%D7%9D_%D7%9E%D7%90%D7%95%D7%A8%D7%92%D7%A0%D7%99%D7%9D_%D7%9C%D7%90%D7%99%D7%A8%D7%95%D7%A4%D7%94',
            '%D7%97%D7%91%D7%99%D7%9C%D7%95%D7%AA_%D7%A0%D7%95%D7%A4%D7%A9_%D7%9C%D7%9B%D7%A8%D7%AA%D7%99%D7%9D',
            '%D7%A7%D7%A0%D7%99%D7%95%D7%AA_%D7%91%D7%A0%D7%90%D7%A4%D7%95%D7%9C%D7%99',
            '%D7%95%D7%A8%D7%A0%D7%94_%D7%97%D7%91%D7%99%D7%9C%D7%95%D7%AA_%D7%A0%D7%95%D7%A4%D7%A9_%D7%9C%D7%95%D7%A8%D7%A0%D7%94_%D7%94%D7%A9%D7%98%D7%99%D7%97_%D7%94%D7%9E%D7%A2%D7%95%D7%A4%D7%A3',
            '%D7%93%D7%99%D7%9C%D7%99%D7%9D_%D7%9C%D7%90%D7%99%D7%A8%D7%95%D7%A4%D7%94',
            '%D7%97%D7%91%D7%99%D7%9C%D7%95%D7%AA_%D7%A1%D7%95%D7%A4%D7%95%D7%A8%D7%98',
            '%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_%D7%9E%D7%95%D7%A2%D7%93%D7%95%D7%9F_%D7%9C%D7%A7%D7%95%D7%97%D7%95%D7%AA_%D7%94%D7%90%D7%AA%D7%A8',
          ],
        },
      },
    },
    {
      path: '/*',
      name: 'notFound',
      component: () => import(/* webpackChunkName: "error-chunk" */ '@/components/404'),
      meta: { title: '500' },
    },
  ],
});
