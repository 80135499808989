import { post } from '@/utils/api';

export default {
  state: () => ({
    // callerInfo: null,
    searchResponse: null,
    productResponse: null,
    openPaxResponse: null,
    openCardResponse: null,
    reachedBookResultResponse: null,
    sentWhatsAppMessage: null,
  }),
  getters: {
    GET_CALLER_INFO: (state, _getters, rootState) => rootState.socketStore.callerInfo,
    GET_SENT_WHATSAPP_MESSAGE_RES: (state) => state.sentWhatsAppMessage,
  },
  mutations: {
    SET_JOURNEY_RES_SEARCH: (state, payload) => {
      state.searchResponse = payload;
    },
    SET_JOURNEY_RES_PRODUCT: (state, payload) => {
      state.productResponse = payload;
    },
    SET_JOURNEY_OPEN_PAX_PAGE: (state, payload) => {
      state.openPaxResponse = payload;
    },
    SET_JOURNEY_OPEN_CARD_PAGE: (state, payload) => {
      state.openCardResponse = payload;
    },
    SET_JOURNEY_REACHED_BOOK_RESULT: (state, payload) => {
      state.reachedBookResultResponse = payload;
    },
    SET_JOURNEY_SENT_WHATSAPP_MESSAGE: (state, payload) => {
      state.sentWhatsAppMessage = payload;
    },
  },
  actions: {
    JOURNEY_SEARCH_HOTELS: async (context, payload) => {
      const { isStaging } = context.rootState;
      if (!isStaging) return;

      const { body } = payload;
      const { callerInfo } = context.rootState.socketStore;
      const { odysseyAgentState } = context.rootState.agencyUsers;

      if (!odysseyAgentState || !callerInfo) return;
      const requestUrl = `/lead/browse/searchedHotel?callerPhone=${callerInfo.callerPhone}`;

      try {
        const response = await post('DOMESTIC', requestUrl, body, context);

        context.commit('SET_JOURNEY_RES_SEARCH', response.data);
      } catch (error) {
        console.warn(error);
      }
    },
    JOURNEY_PRODUCT_PAGES: async (context, payload) => {
      const { isStaging } = context.rootState;
      if (!isStaging) return;

      const { body } = payload;
      const { callerInfo } = context.rootState.socketStore;
      const { odysseyAgentState } = context.rootState.agencyUsers;

      if (!odysseyAgentState || !callerInfo) return;
      const requestUrl = `/lead/browse/openProductPage?callerPhone=${callerInfo.callerPhone}`;

      try {
        const response = await post('DOMESTIC', requestUrl, body, context);

        context.commit('SET_JOURNEY_RES_PRODUCT', response.data);
      } catch (error) {
        console.warn(error);
      }
    },
    JOURNEY_OPEN_PAX_PAGE: async (context) => {
      const { isStaging } = context.rootState;
      if (!isStaging) return;

      const { callerInfo } = context.rootState.socketStore;
      const { odysseyAgentState } = context.rootState.agencyUsers;
      const bookTransId = context.rootState.bookingDataID || window.localStorage.getItem('BookingDataID');

      if (!odysseyAgentState || !bookTransId || !callerInfo) return;
      const requestUrl = `/lead/browse/openPaxPage?callerPhone=${callerInfo.callerPhone}&bookTransId=${bookTransId}`;

      try {
        const response = await post('DOMESTIC', requestUrl, {}, context);

        context.commit('SET_JOURNEY_OPEN_PAX_PAGE', response.data);
      } catch (error) {
        console.warn(error);
      }
    },
    JOURNEY_OPEN_CARD_PAGE: async (context) => {
      const { isStaging } = context.rootState;
      if (!isStaging) return;

      const { callerInfo } = context.rootState.socketStore;
      const { odysseyAgentState } = context.rootState.agencyUsers;
      const bookTransId = context.rootState.bookingDataID || window.localStorage.getItem('BookingDataID');

      if (!odysseyAgentState || !bookTransId || !callerInfo) return;
      const requestUrl = `/lead/browse/openCardPage?callerPhone=${callerInfo.callerPhone}&bookTransId=${bookTransId}`;

      try {
        const response = await post('DOMESTIC', requestUrl, {}, context);

        context.commit('SET_JOURNEY_OPEN_CARD_PAGE', response.data);
      } catch (error) {
        console.warn(error);
      }
    },
    JOURNEY_REACHED_BOOK_RESULT: async (context) => {
      const { isStaging } = context.rootState;
      if (!isStaging) return;

      const { callerInfo } = context.rootState.socketStore;
      const { odysseyAgentState } = context.rootState.agencyUsers;
      const bookTransId = context.rootState.bookingDataID || window.localStorage.getItem('BookingDataID');

      if (!odysseyAgentState || !bookTransId || !callerInfo) return;
      const requestUrl = `/lead/browse/reachedBookResultPage?callerPhone=${callerInfo.callerPhone}&bookTransId=${bookTransId}`;

      try {
        const response = await post('DOMESTIC', requestUrl, {}, context);

        context.commit('SET_JOURNEY_REACHED_BOOK_RESULT', response.data);
      } catch (error) {
        console.warn(error);
      }
    },
    JOURNEY_SEND_WHATSAPP_MESSAGE: async (context, payload) => {
      const { isStaging } = context.rootState;
      if (!isStaging) return;

      const { callerInfo } = context.rootState.socketStore;
      const { odysseyAgentState } = context.rootState.agencyUsers;

      if (!odysseyAgentState || !callerInfo) return;
      const requestUrl = `/lead/leadinfo/messageToFirstAnsweredAgent?callerPhone=${callerInfo.callerPhone}`;

      try {
        const response = await post('DOMESTIC', requestUrl, payload, context);

        context.commit('SET_JOURNEY_SENT_WHATSAPP_MESSAGE', response.data);
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
