import dayjs from 'dayjs';

function toBase64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

function fromBase64(b64) {
  return decodeURIComponent(escape(window.atob(b64)));
}

function formatTwoDate(from, to) {
  const checkIn = dayjs(from).format('D.M');
  const checkOut = dayjs(to).format('D.M');
  return `${checkIn}-${checkOut}`;
}

export {
  toBase64, fromBase64, formatTwoDate,
};
