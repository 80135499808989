import Vue from 'vue';
import Vuex from 'vuex';

import {
  get,
  post,
  postWithText,
} from '@/utils/api';
import { toBase64, formatTwoDate } from '@/utils/base64Util';
import agencyUsers from './modules/agencyUsers';
import memberUsers from './modules/memberUsers';
import socketStore from './modules/socketStore';
import pendingStore from './modules/pendingStore';
import cancelBookStore from './modules/cancelBookStore';
import dashboardMarketer from './modules/dashboardMarketer';
import journeyBrowsing from './modules/journeyBrowsing';

const { VUE_APP_GOOGLE_PHONE_NUMBER, VUE_APP_FACEBOOK_PHONE_NUMBER, VUE_APP_DESKTOP_PHONE_NUMBER, VUE_APP_MOBILE_PHONE_NUMBER, VUE_APP_VACATION_PHONE_NUMBER } = process.env;
const { VUE_APP_LANDING_PAGE_KEY, VUE_APP_LANDING_PAGE_CHAIN_KEY, VUE_APP_LANDING_UTM_SOURCE_KEY } = process.env;
const { VUE_APP_SUPPLIER_SEARCH_LIST } = process.env;

const languageList = { en: 'ENG', he: 'HEB', ru: 'RUS', ar: 'ARA' };

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    agencyUsers,
    memberUsers,
    socketStore,
    pendingStore,
    cancelBookStore,
    dashboardMarketer,
    journeyBrowsing,
  },
  state: {
    isStaging: false,
    developMode: false,
    alertMessage: '',
    alertMessageShow: false,
    categories: null,
    calendarData: null,
    holidayData: null,
    packages: null,
    currentCategory: null,
    isLoading: false,
    dateLoading: false,
    bookingInfo: null,
    bookingDataID: '',
    listDestAtlantisDeal: null,
    dealsAtlantis: null,
    dealType: [],
    dealData: [],
    language: 'he',
    langCode: 'HEB',
    isHebPage: true,
    device: 'desktop',
    mediaSize: 'large-size',
    verifyingURL: '',
    bannerImages: null,
    destinationImages: [],
    whiteLabel: {},
    footerPagesList: null,
    footerCategoryList: null,
    footerPageContent: null,
    // domestic part:
    domesticDestination: [],
    domesticHotels: [],
    searchContent: {
      dest: '',
      hotels: [],
      from: '',
      to: '',
      adult: 0,
      child: 0,
      infant: 0,
    },
    typeChannel: '',
    currentPage: 'home',
    oneHotelInfo: null,
    isUrlContainGoogle: false,
    isUrlContainFacebook: false,
    hotelChain: [],
    hotelActiveChain: [],
    hotelCategory: [],
    hotelAllInfo: [],
    messageFromServer: '',
    // Landing page part
    destDataForLp: null,
    isLandingPage: false,
    isLandingPageChain: false,
    landingInfo: null,
    landingDealData: [],
    landingDealChainData: [],

    errorResponse: null,
    isProcessingBooking: false,
    /**
     * Error message part
     */
    errorContent: null,

    // Multiple search feature
    isMultiSearchDatesState: false,
    isMultiSearchDestinationState: false,

  },
  getters: {
    GET_IS_STAGING_MODE: (state) => state.isStaging,
    GET_MODE: (state) => state.developMode,
    GET_ALERT_MESSAGE: (state) => state.alertMessage,
    GET_ALERT_MESSAGE_SHOW: (state) => state.alertMessageShow,
    GET_CATEGORIES: (state) => state.categories,
    GET_CALENDAR_DATA: (state) => state.calendarData,
    GET_HOLIDAY_DATA: (state) => state.holidayData,
    GET_PACKAGES: (state) => state.packages,
    GET_CURRENT_CATEGORY: (state) => state.currentCategory,
    GET_LOADING_STATE: (state) => state.isLoading,
    GET_DATE_LOADING_STATE: (state) => state.dateLoading,
    GET_BOOKING_INFO: (state) => state.bookingINfo,
    GET_BOOKING_DATA_ID: (state) => state.bookingDataID,
    GET_DESTINATIONS_ATLANTIS_DEALS: (state) => state.listDestAtlantisDeal,
    GET_DEALS_ATLANTIS: (state) => state.dealsAtlantis,
    GET_DEAL_TYPE: (state) => state.dealType,
    GET_DEAL_DATA: (state) => state.dealData,
    GET_LANGUAGE: (state) => state.language,
    GET_LANGUAGECODE: (state) => state.langCode,
    GET_IS_HEBPAGE: (state) => state.isHebPage,
    GET_DEVICE: (state) => state.device,
    GET_MEDIA_SIZE: (state) => state.mediaSize,
    GET_VERIFYING_URL: (state) => state.verifyingURL,
    GET_BANNER_IMAGES: (state) => state.bannerImages,
    GET_DESTINATION_IMAGES: (state) => state.destinationImages,
    GET_URL_CONTAIN_GOOGLE: (state) => state.isUrlContainGoogle,
    GET_URL_CONTAIN_FACEBOOK: (state) => state.isUrlContainFacebook,
    // domestic part:
    GET_WHITE_LABEL_DATA: (state) => state.whiteLabel,
    GET_FOOTER_PAGES_LIST: (state) => state.footerPagesList,
    GET_FOOTER_CATEGORY_LIST: (state) => state.footerCategoryList,
    GET_FOOTER_PAGE_CONTENT: (state) => state.footerPageContent,
    GET_TYPE_CHANNEL: (state) => state.typeChannel,
    GET_DOMESTIC_DESTINATION: (state) => state.domesticDestination,
    GET_DOMESTIC_HOTELS: (state) => state.domesticHotels,
    GET_SEARCH_CONTENT: (state) => state.searchContent,
    GET_ONE_HOTEL_INFO: (state) => state.oneHotelInfo,
    GET_HOTEL_CHAIN_TABLE: (state) => state.hotelChain,
    GET_HOTEL_ACTIVE_CHAIN: (state) => state.hotelActiveChain,
    GET_HOTEL_CATEGORY: (state) => state.hotelCategory,
    GET_HOTEL_ALL_INFO: (state) => state.hotelAllInfo,
    GET_MESSAGE_FROM_SERVER: (state) => state.messageFromServer,
    // Landing page part
    GET_DEST_DATA_FOR_LP: (state) => state.destDataForLp,
    GET_IS_LANDING_PAGE: (state) => state.isLandingPage,
    GET_IS_LANDING_PAGE_CHAIN: (state) => state.isLandingPageChain,
    GET_LANDING_INFO: (state) => state.landingInfo,
    GET_LANDING_DEAL_DATA: (state) => state.landingDealData,
    GET_LANDING_DEAL_CHAIN_DATA: (state) => state.landingDealChainData,

    GET_CURRENT_PAGE: (state) => state.currentPage,
    GET_ERROR_RESPONSE: (state) => state.errorResponse,
    GET_PROCESSING_BOOKING_STATE: (state) => state.isProcessingBooking,
    // Error message part
    GET_ERROR_CONTENT_INFO: (state) => state.errorContent,

    // agency info of site
    GET_AGENCY_NAME: (state) => {
      let name = '';
      if (state.whiteLabel && state.whiteLabel.forMainSite) {
        name = state.whiteLabel.odyAgentCode;
      }
      return name;
    },

    // multiple search case
    GET_MULTI_SEARCH_DATES_STATE: (state) => state.isMultiSearchDatesState,
    GET_MULTI_SEARCH_DESTINATION_STATE: (state) => state.isMultiSearchDestinationState,
    GET_IS_MULTIPLE_SEARCH_CASE: (state) => state.device === 'desktop' && state.agencyUsers.odysseyAgentState,
  },
  mutations: {
    SET_STAGING_STATE: (state, payload) => {
      state.isStaging = payload;
    },
    SET_ALERT_MESSAGE: (state, payload) => {
      state.alertMessage = payload;
    },
    SET_ALERT_MESSAGE_SHOW: (state, payload) => {
      state.alertMessageShow = payload;
    },
    SET_CATEGORIES: (state, payload) => {
      state.categories = payload;
    },
    SET_CALENDAR_DATA: (state, payload) => {
      state.calendarData = payload;
    },
    SET_HOLIDAY_DATA: (state, payload) => {
      state.holidayData = payload;
    },
    SET_PACKAGES: (state, payload) => {
      state.packages = payload;
    },
    SET_CURRENT_CATEGORY: (state, payload) => {
      state.currentCategory = state.categories.find((item) => (item.code === payload));
    },
    SET_LOADING_STATE: (state, payload) => {
      state.isLoading = payload;
    },
    SET_DATE_LOADING_STATE: (state, payload) => {
      state.dateLoading = payload;
    },
    SET_SEARCH_ITEM_CONTENT: (state, payload) => {
      state.searchContent[payload[0]] = payload[1];
    },
    SET_DESTINATIONS_ATLANTIS_DEALS: (state, payload) => {
      state.listDestAtlantisDeal = payload;
    },
    SET_DEALS_ATLANTIS: (state, payload) => {
      state.dealsAtlantis = { ...state.dealsAtlantis, ...payload };
    },
    SET_DEAL_TYPE: (state, payload) => {
      state.dealType = payload;
    },
    SET_DEAL_DATA: (state, payload) => {
      state.dealData = payload;
    },
    SET_LANGUAGE: (state, payload) => {
      state.language = payload;
      state.langCode = languageList[payload];
      state.isHebPage = payload === 'he';
    },
    SET_DEVICE: (state, payload) => {
      state.device = payload;
    },
    SET_MEDIA_SIZE: (state, payload) => {
      state.mediaSize = payload;
    },
    SET_VERIFYING_URL: (state, payload) => {
      state.verifyingURL = payload;
    },
    SET_BANNER_IMAGES: (state, payload) => {
      state.bannerImages = payload;
    },
    SET_DESTINATION_IMAGES: (state, payload) => {
      state.destinationImages = payload;
    },
    SET_WHITE_LABEL: (state, payload) => {
      state.whiteLabel = payload;

      if (!payload.phoneNumber) {
        if (state.isUrlContainGoogle) {
          state.whiteLabel.phoneNumber = VUE_APP_GOOGLE_PHONE_NUMBER;
        } else if (state.isUrlContainFacebook) {
          state.whiteLabel.phoneNumber = VUE_APP_FACEBOOK_PHONE_NUMBER;
        } else if (state.device === 'desktop') {
          state.whiteLabel.phoneNumber = VUE_APP_DESKTOP_PHONE_NUMBER;
        } else if (state.device === 'mobile') {
          state.whiteLabel.phoneNumber = VUE_APP_MOBILE_PHONE_NUMBER;
        }
      }
      state.whiteLabel.vacationPhoneNumber = VUE_APP_VACATION_PHONE_NUMBER;
    },
    SET_FOOTER_PAGES_LIST: (state, payload) => {
      state.footerPagesList = (payload === undefined || payload === '') ? null : payload;
    },
    SET_FOOTER_CATEGORY_LIST: (state, payload) => {
      state.footerCategoryList = (payload === undefined || payload === '') ? null : payload;
    },
    SET_FOOTER_PAGE_CONTENT: (state, payload) => {
      state.footerPageContent = (payload === undefined || payload === '') ? null : payload;
    },
    SET_TYPE_CHANNEL: (state, payload) => {
      state.typeChannel = payload;
    },
    /**
     *  domestic part:
     *  */
    SET_DOMESTIC_DESTINATION: (state, payload) => {
      state.domesticDestination = payload;
    },
    SET_DOMESTIC_HOTELS: (state, payload) => {
      state.domesticHotels = payload;
    },
    SET_ONE_HOTEL_INFO: (state, payload) => {
      state.oneHotelInfo = payload;
    },
    SET_BOOKING_INFO: (state, payload) => {
      state.bookingInfo = payload;
    },
    SET_BOOKING_DATA_ID: (state, payload) => {
      state.bookingDataID = payload;
    },
    SET_URL_CONTAIN_GOOGLE: (state) => {
      state.isUrlContainGoogle = true;
    },
    SET_URL_CONTAIN_FACEBOOK: (state) => {
      state.isUrlContainFacebook = true;
    },
    SET_HOTEL_CHAIN_TABLE: (state, payload) => {
      state.hotelChain = payload;
    },
    SET_HOTEL_ACTIVE_CHAIN: (state, payload) => {
      state.hotelActiveChain = payload;
    },
    SET_HOTEL_CATEGORY: (state, payload) => {
      state.hotelCategory = payload;
    },
    SET_HOTEL_ALL_INFO: (state, payload) => {
      state.hotelAllInfo = payload;
    },
    SET_CURRENT_PAGE: (state, payload) => {
      state.currentPage = payload;
    },

    // Landing page part
    SET_DEST_DATA_FOR_LP: (state, payload) => {
      state.destDataForLp = payload;
    },
    SET_LANDING_PAGE: (state, payload) => {
      state.isLandingPage = payload;
    },
    SET_LANDING_PAGE_CHAIN: (state, payload) => {
      state.isLandingPageChain = payload;
    },
    SET_LANDING_INFO: (state, payload) => {
      state.landingInfo = payload;
    },
    SET_LANDING_DEAL_DATA: (state, payload) => {
      state.landingDealData = payload;
    },
    SET_LANDING_DEAL_CHAIN_DATA: (state, payload) => {
      state.landingDealChainData = payload;
    },
    SET_ERROR_RESPONSE: (state, payload) => {
      state.errorResponse = payload;
    },
    SET_PROCESSING_BOOKING_STATE: (state, payload) => {
      state.isProcessingBooking = payload;
    },
    /**
     * Error message part
     */
    SET_ERROR_MESSAGE_INFO: (state, payload) => {
      state.errorContent = payload;
    },
    /**
     * Multiple search feature
     */
    SET_MULTIPLE_DATES_CASE: (state, payload) => {
      state.isMultiSearchDatesState = payload;
    },
    SET_MULTIPLE_DESTINATION_CASE: (state, payload) => {
      state.isMultiSearchDestinationState = payload;
    },
  },
  actions: {
    UPDATE_DEVICE: (context, payload) => {
      context.commit('SET_DEVICE', payload);
    },
    UPDATE_MEDIA_SIZE: (context, payload) => {
      context.commit('SET_MEDIA_SIZE', payload);
    },
    UPDATE_LANGUAGE: (context, payload) => {
      context.commit('SET_LANGUAGE', payload);
    },
    UPDATE_CURRENT_CATEGORY: (context, payload) => {
      const {
        categoryId,
      } = payload;
      context.commit('SET_CURRENT_CATEGORY', categoryId);
    },
    FETCH_DESTINATIONS_ATLANTIS_DEALS: async (context) => {
      context.commit('SET_DATE_LOADING_STATE', true);
      try {
        if (context.state.listDestAtlantisDeal === null) {
          const response = await get('DOMESTIC', '/deal/calendarDates', context);
          context.commit('SET_DESTINATIONS_ATLANTIS_DEALS', response.data);
          context.commit('SET_DATE_LOADING_STATE', false);
        } else {
          context.commit('SET_DATE_LOADING_STATE', false);
        }
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        context.commit('SET_DATE_LOADING_STATE', false);
      }
    },
    FETCH_DEALS_ATLANTIS: async (context, payload) => {
      context.commit('SET_DATE_LOADING_STATE', true);
      const { dest, checkIn, checkOut } = payload;
      try {
        if (!dest || (context.state.dealsAtlantis && context.state.dealsAtlantis[dest])) return;
        let url = `/deal/atlDealsByDest/${dest}`;
        if (checkIn) url = `${url}?chkin=${checkIn}`;
        if (checkOut) {
          url = url + (!checkIn) ? '?' : '&';
          url = `${url}chkout=${checkOut}`;
        }
        url += `${(!checkIn && !checkOut) ? '?' : '&'}langCode=${context.state.langCode}`;
        const response = await get('DOMESTIC', url, context);
        context.commit('SET_DEALS_ATLANTIS', { [dest]: response.data });
        context.commit('SET_DATE_LOADING_STATE', false);
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        context.commit('SET_DATE_LOADING_STATE', false);
      }
    },
    FETCH_HOLIDAY_DATA: async (context, payload) => {
      const { agencyAuthorized } = context.state.agencyUsers;
      context.commit('SET_DATE_LOADING_STATE', true);
      try {
        const response = await get('BFF', `/operation/packDates/holidays?year=${payload}&year=${payload + 1}`, context);
        const holidays = [...response.data[0].holidays, ...response.data[1].holidays];
        context.commit('SET_HOLIDAY_DATA', holidays);
        context.commit('SET_DATE_LOADING_STATE', false);
      } catch (error) {
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        context.commit('SET_ERROR_RESPONSE', error.response);
      }
    },
    SET_SEARCH_ITEM: async (context, payload) => {
      if (payload && payload[0] === 'dest' && payload[1].length > 1) {
        context.commit('SET_MULTIPLE_DESTINATION_CASE', true);
      }
      if (payload && payload[0] === 'dest' && payload[1].length === 1) {
        context.commit('SET_MULTIPLE_DESTINATION_CASE', false);
      }
      context.commit('SET_SEARCH_ITEM_CONTENT', payload);
    },
    GET_SUCCEED_BOOKING: async (context) => {
      context.commit('SET_PROCESSING_BOOKING_STATE', true);
      const BookingDataID = window.localStorage.getItem('BookingDataID');
      try {
        const response = await get('DOMESTIC', `/operation/succeededBooking/${BookingDataID}`, context);
        // const response = await get('DOMESTIC', `/operation/succeededBooking/8922c7c9-07e1-492d-afff-4f7df5d0d553`, context);
        context.commit('SET_PROCESSING_BOOKING_STATE', false);
        return response;
      } catch (error) {
        context.commit('SET_PROCESSING_BOOKING_STATE', false);
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    FETCH_DESTINATION_IMAGES: async (context) => {
      try {
        const response = await get('BFF', '/destinationEx/?from=1&to=1000', context);
        context.commit('SET_DESTINATION_IMAGES', response.data.data);
        return response.data.data;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    FETCH_WHITE_LABEL: async (context, payload) => {
      try {
        const response = await get('BFF', `/agencyContents/byHost/${payload}`, context);
        context.commit('SET_WHITE_LABEL', response.data);
        context.commit('SET_ODYSSEY_AGENT_CONTENT', response.data);
        if (response.data.odyAgentCode === 'FCINT') {
          context.commit('SET_AGENCY_OWNER_NAME', 'intweb');
        } else {
          context.commit('SET_AGENCY_CONTENT_MARKETER_ID', response.data);
          context.commit('SET_AGENCY_OWNER_NAME', response.data.odyAgentCode);
          context.commit('SET_MARKETER_ID', response.data.agencyCode);

          if (!context.state.agencyAuthorized) { context.commit('SET_AGENCY_LOGIN_STATE', response.data.isB2BMarketer); }
        }

        return response;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return { data: { status: 404 } };
      }
    },
    FETCH_FOOTER_PAGES_LIST: async (context) => {
      let response = null;
      try {
        response = await get('DOMESTIC', '/footerItem/pageList', context);
        context.commit('SET_FOOTER_PAGES_LIST', response.data);
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        context.commit('SET_FOOTER_PAGES_LIST', null);
      }
      return response;
    },
    FETCH_FOOTER_PAGE_CONTENT: async (context, payload) => {
      let response = null;
      try {
        response = await get('DOMESTIC', `/footerItem/${payload}`, context);
        context.commit('SET_FOOTER_PAGE_CONTENT', response.data);
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        context.commit('SET_FOOTER_PAGE_CONTENT', null);
      }
      return response;
    },
    FETCH_STATIC_PAGE_SUBTYPE_LIST: async (context) => {
      let response = null;
      try {
        response = await get('DOMESTIC', '/footerCategory/activeOnly', context);
        context.commit('SET_FOOTER_CATEGORY_LIST', response.data);
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        context.commit('SET_FOOTER_CATEGORY_LIST', null);
      }
      return response;
    },
    /**
     * domestic site part:
     *  */
    FETCH_DOMESTIC_DESTINATION: async (context) => {
      try {
        const response = await get('DOMESTIC', `/destination?from=1&to=1000&langCode=${context.state.langCode}`, context);
        context.commit('SET_DOMESTIC_DESTINATION', response.data);
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
      }
    },
    FETCH_DOMESTIC_HOTELS: async (context) => {
      try {
        const response = await get('DOMESTIC', `/hotelConversion?from=1&to=1000&langCode=${context.state.langCode}`, context);
        context.commit('SET_DOMESTIC_HOTELS', response.data);
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
      }
    },
    FETCH_PACKAGE_BY_SEARCH: async (context, payload) => {
      const { agencyAuthorized } = context.state.agencyUsers;
      const url = '/operation/search';
      const suppliers = VUE_APP_SUPPLIER_SEARCH_LIST.split(',');
      const { hotelCode, sources } = payload;
      const hotelCodeList = hotelCode ? hotelCode.split(',') : [];
      const sourceArray = sources ? sources.split(',').map((src) => suppliers[src]) : [];
      const responseArray = [];
      payload.langCode = context.state.langCode;
      const body = { ...payload };
      const { subCalling, city, multiSearchDatesState, checkIn, checkOut } = body;

      context.commit('SET_MULTIPLE_DATES_CASE', multiSearchDatesState);

      let response = null;
      if (!subCalling) context.commit('SET_LOADING_STATE', true);

      if (hotelCodeList.length > 0) {
        for (let i = 0; i < hotelCodeList.length; i += 1) {
          body.hotelCode = hotelCodeList[i];
          try {
            if (sources) body.suppliers = [sourceArray[i]];
            // eslint-disable-next-line no-await-in-loop
            response = await post('DOMESTIC', url, body, context);
            responseArray.push(...response.data);
          } catch (error) {
            if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
            context.commit('SET_ERROR_RESPONSE', error.response);
            context.dispatch('NOTIFY_ADMIN', {
              subject: `Domestic - The search is getting an error - ${error.response.status}`,
              body: `<h4>${JSON.stringify(body)}</h4> <p>${url}</p><p>${JSON.stringify(error.response)}</p>`,
            });
          }
        }
      } else {
        try {
          response = await post('DOMESTIC', url, payload, context);
          responseArray.push(...response.data);
        } catch (error) {
          context.commit('SET_ERROR_RESPONSE', error.response);
          context.dispatch('NOTIFY_ADMIN', {
            subject: `Domestic - The search is getting an error - ${error.response.status}`,
            body: `<h4>${JSON.stringify(body)}</h4> <p>${url}</p><p>${JSON.stringify(error.response)}</p>`,
          });
        }
      }
      if (!subCalling) {
        let { packages } = context.state;
        let key = city;
        if (packages === null) packages = {};
        if (multiSearchDatesState) {
          key = formatTwoDate(checkIn, checkOut);
        }
        const newPackagesData = { ...packages, [key]: responseArray };
        context.commit('SET_PACKAGES', newPackagesData);
      }
      if (!subCalling) context.commit('SET_LOADING_STATE', false);

      return responseArray;
    },
    FETCH_DEAL_TYPE_LIST: async (context) => {
      const { agencyAuthorized } = context.state.agencyUsers;
      try {
        const response = await get('DOMESTIC', `/dealCategory/onlyActive?langCode=${context.state.langCode}`, context);
        context.commit('SET_DEAL_TYPE', response.data);
        return response;
      } catch (error) {
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    FETCH_DEAL_DATA: async (context) => {
      const { agencyAuthorized } = context.state.agencyUsers;
      try {
        const response = await get('DOMESTIC', `/deal/inEffect/homepage?langCode=${context.state.langCode}`, context);
        context.commit('SET_DEAL_DATA', response.data);
        return response;
      } catch (error) {
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    FETCH_MORE_HOTEL_INFO: async (context, payload) => {
      context.commit('SET_LOADING_STATE', true);
      const { hotelCode, supplierCode } = payload;
      try {
        const response = await get('DOMESTIC', `/hotelInfo/${supplierCode}/${hotelCode}?langCode=${context.state.langCode}`, context);
        context.commit('SET_ONE_HOTEL_INFO', response.data || null);
        context.commit('SET_LOADING_STATE', false);
        return response;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    FETCH_HOTELS_BY_OCCUPATION: async (context, payload) => {
      const url = '/operation/search';
      payload.lang = context.state.langCode;
      try {
        const response = await post('DOMESTIC', url, payload, context);

        return response.data;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    SEND_BOOKING_DATA: async (context, payload) => {
      const { agencyAuthorized } = context.state.agencyUsers;
      window.localStorage.setItem('BookingDetailInfo', toBase64(JSON.stringify(payload)));
      context.commit('SET_BOOKING_INFO', payload);
      const dataID = payload.sendingStage === 'init' ? '' : context.state.bookingDataID || window.localStorage.getItem('BookingDataID') || '';
      const body = payload.data;
      const url = `/operation/storePage/${dataID}?langCode=${context.state.langCode}`;

      try {
        context.commit('SET_PROCESSING_BOOKING_STATE', true);
        const response = await post('DOMESTIC', url, body, context);
        context.commit('SET_PROCESSING_BOOKING_STATE', false);

        context.commit('SET_BOOKING_DATA_ID', response.data);
        return {
          success: true,
          id: response.data,
        };
      } catch (error) {
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        context.dispatch('NOTIFY_ADMIN', {
          subject: `Domestic - StorePage is getting an error - ${error.response.status}`,
          body: `<h4>${JSON.stringify(payload)}</h4> <p>${url}</p><p>${JSON.stringify(error.response)}</p>`,
        });
        context.commit('SET_ERROR_RESPONSE', error.response);
        return {
          success: false,
          error,
        };
      }
    },
    GET_PAYMENT_URL: async (context, payload) => {
      const { agencyAuthorized } = context.state.agencyUsers;
      context.commit('SET_PROCESSING_BOOKING_STATE', true);
      const url = `/operation/getPaymentUrl/${payload.bookID}?langCode=${context.state.langCode}`;
      try {
        const response = await post('DOMESTIC', url, payload.payerInfo, context);
        context.commit('SET_AGENCY_OWNER_NAME', response.data.agencyOwner);
        window.sessionStorage.setItem('agencyOwner', toBase64(JSON.stringify(response.data.agencyOwner)));
        context.commit('SET_PROCESSING_BOOKING_STATE', false);
        return {
          success: true,
          url: response.data,
        };
      } catch (error) {
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        context.dispatch('NOTIFY_ADMIN', {
          subject: `Domestic - GetPaymentUrl is getting an error - ${error.response.status}`,
          body: `<h4>${JSON.stringify(payload)}</h4> <p>${url}</p><p>${JSON.stringify(error.response)}</p>`,
        });
        context.commit('SET_ERROR_RESPONSE', error.response);
        context.commit('SET_PROCESSING_BOOKING_STATE', false);
        return {
          success: false,
          error,
        };
      }
    },
    UPDATE_VERIFYING_URL: (context, payload) => {
      context.commit('SET_VERIFYING_URL', payload);
    },
    SEND_CLOSING_NOTICE: async (context, payload) => {
      let response = null;
      if (payload.withData) {
        const body = payload.data;
        const url = `/operation/bookingevent/${payload.bookDataID}/canceledWithBody?langCode=${context.state.langCode}`;
        try {
          response = await postWithText('DOMESTIC', url, body, context);
          return response;
        } catch (error) {
          context.commit('SET_ERROR_RESPONSE', error.response);
        }
      } else {
        try {
          response = await post('DOMESTIC', `/operation/bookingevent/${payload.bookDataID}/canceledWithoutBody?langCode=${context.state.langCode}`, undefined, context);
          return response;
        } catch (error) {
          context.commit('SET_ERROR_RESPONSE', error.response);
        }
      }
      return response;
    },
    GET_BOOKED_INFO: async (context, payload) => {
      const { agencyAuthorized } = context.state.agencyUsers;
      const isDirect = payload?.isDirect || false;
      context.commit('SET_PROCESSING_BOOKING_STATE', true);
      const BookingDataID = window.localStorage.getItem('BookingDataID');
      const url = isDirect ? `/operation/resumeThePaymentOnly/${BookingDataID}` : `/operation/payAndBook/${BookingDataID}`;
      try {
        const response = await post('DOMESTIC', url, undefined, context);
        // const response = await post('DOMESTIC', `/operation/payAndBook/4cdc3f76-81a9-48be-84ee-c8972d4d6ba6/?confirmationKey=d617648820a1584937e69f7254a41326&live=false&num=5`, data, context);
        context.commit('SET_PROCESSING_BOOKING_STATE', false);
        return response;
      } catch (error) {
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        context.dispatch('NOTIFY_ADMIN', {
          subject: `Domestic - PayAndBook is getting an error - ${error.response.status}`,
          body: `<h4>${JSON.stringify(payload)}</h4> <p>${url}</p><p>${JSON.stringify(error.response)}</p>`,
        });

        context.commit('SET_ERROR_RESPONSE', error.response);
        context.commit('SET_PROCESSING_BOOKING_STATE', false);
        return error;
      }
    },
    FETCH_BANNER_IMAGES: async (context, payload) => {
      const { agencyAuthorized } = context.state.agencyUsers;
      const { page } = payload;
      try {
        const response = await get('BFF', `/pageContentsAggregate/byPage/${page}?langCode=${context.state.langCode}`, context);
        context.commit('SET_BANNER_IMAGES', response.data);
        return response.data;
      } catch (error) {
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    FETCH_COUPON_INFO: async (context, payload) => {
      const { code, originalPrice, checkInDate, supplier, dealId } = payload;
      const paramDealId = dealId ? `&dealId=${dealId}` : '';
      let response = null;
      try {
        let url = `/operation/checkCoupon/${code}?amount=${originalPrice}&checkinDate=${checkInDate}&supplier=${supplier}${paramDealId}`;
        const { subAgency } = context.state.agencyUsers;
        if (subAgency && subAgency !== '') url = `${url}&subAgency=${subAgency}`;
        const res = await get('DOMESTIC', url, context);
        response = res.data;
        return response;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        if (String(error).includes('400')) {
          response = {
            type: 'https://tools.ietf.org/html/rfc7231#section-6.5.1',
            title: 'One or more validation errors occurred.',
            status: 400,
            errors: {
              field: [
                'booking.invalid-coupon-price',
              ],
            },
          };
        } else {
          response = {
            type: 'https://tools.ietf.org/html/rfc7231#section-6.5.1',
            title: 'One or more validation errors occurred.',
            status: 500,
            errors: {
              field: [
                'booking.invalid-coupon-number',
              ],
            },
          };
        }
        return response;
      }
    },
    FETCH_HOTEL_CHAIN: async (context) => {
      try {
        const response = await get('DOMESTIC', `/hotelChain?from=1&to=1000&langCode=${context.state.langCode}`, context); // all chain list
        context.commit('SET_HOTEL_CHAIN_TABLE', response.data.data);

        const responseID = await get('DOMESTIC', '/deal/inEffectLp/homepage/chain', context); // chain list with deals
        context.commit('SET_HOTEL_ACTIVE_CHAIN', responseID.data);
        return response.data.data;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    FETCH_HOTEL_CATEGORY: async (context) => {
      try {
        const response = await get('DOMESTIC', '/deal/inEffectLp/homepage/category', context); // category list with deals
        context.commit('SET_HOTEL_CATEGORY', response.data);
        return response.data.data;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    FETCH_ALL_HOTEL_INFO: async (context) => {
      context.commit('SET_LOADING_STATE', true);
      try {
        const response = await get('DOMESTIC', '/hotelInfo?from=1&to=1000', context);
        context.commit('SET_HOTEL_ALL_INFO', response.data.data);
        context.commit('SET_LOADING_STATE', false);
        return response.data.data;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    FETCH_TZNUMBER_CHECK_RESULT: async (context, payload) => {
      const { tzNumber } = payload;
      const formData = new FormData();
      formData.append('tzNumber', tzNumber);

      context.commit('SET_LOADING_STATE', true);
      try {
        const response = await post('DOMESTIC', '/IrgonMember/checkHistory', formData, context);
        context.commit('SET_LOADING_STATE', false);
        return response.data;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    // Landing part
    FETCH_DEST_DATA_FOR_LP: async (context) => {
      try {
        const response = await get('DOMESTIC', '/deal/inEffectLp/homepage/dest', context);
        context.commit('SET_DEST_DATA_FOR_LP', response.data);
        return response;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    STORE_IS_LANDING_STATE: (context, payload) => {
      context.commit('SET_LANDING_PAGE', VUE_APP_LANDING_PAGE_KEY in payload);
      context.commit('SET_LANDING_PAGE_CHAIN', VUE_APP_LANDING_PAGE_CHAIN_KEY in payload);
    },
    STORE_LANDING_URL: async (context, payload) => {
      const utmSource = payload[VUE_APP_LANDING_UTM_SOURCE_KEY] || 'general';
      // eslint-disable-next-line no-nested-ternary
      const phone = utmSource === 'google' ? VUE_APP_GOOGLE_PHONE_NUMBER : (utmSource === 'facebook' ? VUE_APP_FACEBOOK_PHONE_NUMBER : VUE_APP_DESKTOP_PHONE_NUMBER);
      const dealType = payload.dealType || '';

      const info = {
        destination: payload[VUE_APP_LANDING_PAGE_KEY],
        chainName: payload[VUE_APP_LANDING_PAGE_CHAIN_KEY],
        utm_source: utmSource,
        phone,
        dealType,
      };
      if (context.state.isLandingPage) {
        context.commit('SET_LANDING_INFO', info);
        try {
          const response = await get('DOMESTIC', `/deal/inEffectLp/homepage/?destCode=${info.destination}`, context);
          context.commit('SET_LANDING_DEAL_DATA', response.data || []);
        } catch (error) {
          context.commit('SET_ERROR_RESPONSE', error.response);
        }
      } else if (context.state.isLandingPageChain) {
        context.commit('SET_LANDING_INFO', info);
        try {
          const response = await get('DOMESTIC', `/deal/inEffectLp/homepage/?chainName=${info.chainName}`, context);
          context.commit('SET_LANDING_DEAL_CHAIN_DATA', response.data || []);
        } catch (error) {
          context.commit('SET_ERROR_RESPONSE', error.response);
        }
      }
    },
    SEND_CONTACT_FORM: async (context, payload) => {
      try {
        const response = await post('DOMESTIC', '/lead/leadInfo', payload, context);
        return response.data;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    NOTIFY_ADMIN_404_ERROR: async (context, payload) => {
      try {
        const res = await post('BFF', '/operation/notifyAdmin/pageNotFound', payload, context);
        return res.data.redirectPath;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return null;
      }
    },
    NOTIFY_ADMIN: async (context, payload) => {
      try {
        await post('BFF', '/operation/notifyAdmin', payload, context);
        return null;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    // Sending Google analytic event
    SENDING_GA_EVENT: (context, payload) => {
      const { eventName, items, transactionId, value, currency, coupon, tax, shipping, itemListId, itemListName } = payload;

      console.warn(`Triggered ${eventName} event`);
      const body = {};

      if (transactionId) body.transaction_id = transactionId;
      if (value) body.value = value;
      if (currency) body.currency = currency;
      if (coupon) body.coupon = coupon;
      if (tax) body.tax = tax;
      if (shipping) body.shipping = shipping;
      if (itemListId) body.item_list_id = itemListId;
      if (itemListName) body.item_list_name = itemListName;

      const itemList = items.map((itm) => ({
        item_id: itm.item_id,
        name: itm.name || '',
        item_name: itm.item_name,
        affiliation: itm.affiliation,
        coupon: itm.coupon || '',
        discount: itm.discount || '',
        index: 0,
        item_brand: itm.item_brand || '',
        item_category: itm.item_category || '',
        item_category2: itm.item_category2 || '',
        item_category3: '',
        item_category4: '',
        item_category5: '',
        item_list_id: '',
        item_list_name: itm.item_list_name || '',
        item_variant: itm.item_variant || '',
        location_id: '',
        price: itm.price || 0,
        quantity: 1,
      }));
      body.items = itemList;
      window.gtag('event', eventName, body);
    },
  },
});
