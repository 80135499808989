import { get,
  post,
} from '@/utils/api';

export default {
  state: () => ({
    cancelBookingInfo: null,
    cancelState: null,
    cancelBookingMoreInfo: null,
    cancelCorrExtPnrs: null,

    passwordState: false,
    passErrorMessage: '',
  }),
  getters: {
    GET_CANCEL_BOOKING_INFO: (state) => state.cancelBookingInfo,
    GET_CANCEL_STATE: (state) => state.cancelState,
    GET_CANCEL_BOOKING_MORE_INFO: (state) => state.cancelBookingMoreInfo,
    GET_CANCEL_CORRESPONDING_EXT_PNRS: (state) => state.cancelCorrExtPnrs,

    GET_PASSWORD_STATE: (state) => state.passwordState,
    GET_PASS_ERROR_MESSAGE: (state) => state.passErrorMessage,
  },
  mutations: {
    SET_CANCEL_BOOKING_INFO: (state, payload) => {
      state.cancelBookingInfo = payload;
    },
    SET_CANCEL_STATE: (state, payload) => {
      state.cancelState = payload;
    },
    SET_CANCEL_BOOKING_MORE_INFO: (state, payload) => {
      state.cancelBookingMoreInfo = payload;
    },
    SET_CANCEL_CORRESPONDING_EXT_PNRS: (state, payload) => {
      state.cancelCorrExtPnrs = payload;
    },
    SET_PASSWORD_STATE: (state, payload) => {
      state.passwordState = payload;
    },
    SET_PASS_ERROR_MESSAGE: (state, payload) => {
      state.passErrorMessage = payload;
    },
  },
  actions: {
    SEND_CANCEL_PNR_FOR_GET_INFO: async (context, payload) => {
      const { pnr, isExternal } = payload;
      try {
        const response = await get('DOMESTIC', `/operation/pnrDetails/${pnr}/?isExtPnr=${isExternal}`, context);

        context.commit('SET_CANCEL_BOOKING_INFO', response.data.data);
        context.commit('SET_CANCEL_CORRESPONDING_EXT_PNRS', response.data.pnrExtPnrs);

        context.commit('SET_LOADING_STATE', false);
        return response;
      } catch (e) {
        context.commit('SET_LOADING_STATE', false);
        return e;
      }
    },
    SUBMIT_CANCEL_PERMISSION: async (context, payload) => {
      const { pnr, extPnr, password } = payload;
      const formData = new FormData();
      formData.append('password', password);
      try {
        const response = await post('DOMESTIC', `/operation/cancelBook/${pnr}/?extPnr=${extPnr}`, formData, context, null, true);
        context.commit('SET_CANCEL_STATE', response.data);
        context.commit('SET_LOADING_STATE', false);
        return response;
      } catch (e) {
        context.commit('SET_LOADING_STATE', false);
        return e;
      }
    },
    FETCH_MORE_DETAIL_FOR_CANCEL_BOOKING: async (context, payload) => {
      const { supplierCode, externalCode, extPnr } = payload;
      try {
        const response = await get('DOMESTIC', `/operation/extBookInfo/?supplier=${supplierCode}&hotelCode=${externalCode}&extPnr=${extPnr}`, context);
        context.commit('SET_CANCEL_BOOKING_MORE_INFO', response?.data || null);
        context.commit('SET_LOADING_STATE', false);
        return response;
      } catch (e) {
        context.commit('SET_LOADING_STATE', false);
        return e;
      }
    },
    CHECK_CANCELLATION_PASSWORD: async (context, payload) => {
      const { password } = payload;
      const formData = new FormData();
      formData.append('password', password);
      try {
        const response = await post('DOMESTIC', '/operation/cancelBook/preflight', formData, context, null, true);
        if (response.status === 200 || response.status === 204) {
          context.commit('SET_PASSWORD_STATE', true);
          context.commit('SET_PASS_ERROR_MESSAGE', '');
          sessionStorage.setItem('cancelPassword', password);
        } else {
          context.commit('SET_PASS_ERROR_MESSAGE', 'Something error!');
          context.commit('SET_PASSWORD_STATE', false);
        }
      } catch (e) {
        context.commit('SET_PASSWORD_STATE', false);
        if (e.response.status > 399 || e.response.status < 500) {
          context.commit('SET_PASS_ERROR_MESSAGE', 'The password is incorrect. Try again.');
        } else {
          context.commit('SET_PASS_ERROR_MESSAGE', 'Sorry. It seems the server has an error. Try again.');
        }
      }
    },
    RESET_CANCEL_MORE_INFO: async (context) => {
      context.commit('SET_CANCEL_BOOKING_MORE_INFO', null);
      context.commit('SET_CANCEL_STATE', null);
    },
  },
};
