import { get, post, utf8ToB64, b64ToUtf8 } from '@/utils/api';

export default {
  state: () => ({
    agencyAuthorized: false,
    isAgencyAuthoring: false,
    agencyUser: null,
    agencyUserPhoneNumber: null,
    userAgencySid: null,
    agencyUserVerifyCode: null,
    agencyErrorMessage: null,
    isAgencyLoginState: false,

    // marketer part. Moved from main store.
    marketerId: '',
    marketerAgency: '',
    marketerAgencyContent: null,
    bypassPayment: false,
    subAgency: '',
    partnerId: '',

    // Odyssey agent part
    odysseyAgentState: false,
    odysseyAgentContent: null,
    odysseyAgentClerkName: '',
    selectedOdysseyAgentCode: null,
    loginOdyAgentCode: null,
    isEmalonMode: false,

    allAgentList: [],

    // booking info part
    loadingBookingInfo: false,
    bookingInfoList: null,

    // for google analytics only
    agencyOwnerName: '',
    affiliation: '',

    // for direct payment state
    directPaymentState: false,
    paymentOnlyResponse: null,
  }),
  getters: {
    IS_AGENCY_AUTHORIZED: (state) => state.agencyAuthorized, // internal odyssey agent or agency login state
    GET_AGENCY_ERROR_MESSAGE: (state) => state.agencyErrorMessage,
    GET_IS_AGENCY_AUTHORING: (state) => state.isAgencyAuthoring,
    GET_AGENCY_SID: (state) => state.userAgencySid,
    GET_AGENCY_USER: (state) => state.agencyUser,
    GET_AGENCY_LOGIN_STATE: (state) => state.isAgencyLoginState,
    GET_IS_EMALON_MODE: (state) => state.isEmalonMode,

    // marketer part. Moved from main store.
    GET_MARKETER_ID: (state) => state.marketerId,
    GET_MARKETER_AGENCY: (state) => state.marketerAgency,
    GET_MARKETER_AGENCY_CONTENT: (state) => state.marketerAgencyContent,
    GET_BYPASS_PAYMENT_STATE: (state) => state.bypassPayment, // BEZEQ marketer or bypass payment of internal odyssey agent
    GET_SUB_AGENCY: (state) => state.subAgency,

    // Odyssey agent part
    GET_ODYSSEY_AGENT_STATE: (state) => state.odysseyAgentState, // external or internal agent site
    GET_ODYSSEY_AGENT_CONTENT: (state) => state.odysseyAgentContent,
    GET_ODYSSEY_AGENT_CLERK_NAME: (state) => state.odysseyAgentClerkName,
    GET_SELECTED_ODYSSEY_AGENT_CODE: (state) => state.selectedOdysseyAgentCode,

    GET_LOGIN_ODYSSEY_AGENT_CODE: (state) => state.loginOdyAgentCode,
    // internal agent site
    IS_FCL_ODYSSEY_AGENT_CODE: (state, _getters, rootState) => (rootState.whiteLabel.dabAllowedOdyAgentCodes ? rootState.whiteLabel.dabAllowedOdyAgentCodes.includes(state.loginOdyAgentCode?.toUpperCase()) || rootState.whiteLabel.dabAllowedOdyAgentCodes.includes(state.loginOdyAgentCode?.toLowerCase()) : false),

    GET_ALL_AGENT_LIST: (state) => state.allAgentList,
    GET_HIDE_CONDITION: (state, getter) => (getter.IS_AGENCY_AUTHORIZED && !getter.IS_FCL_ODYSSEY_AGENT_CODE) || (getter.GET_IS_EMALON_MODE && getter.IS_FCL_ODYSSEY_AGENT_CODE),

    // partner
    GET_PARTNER_ID: (state) => state.partnerId,

    // booking info part
    GET_LOADING_BOOKING_INFO: (state) => state.loadingBookingInfo,
    GET_BOOKING_INFO_LIST: (state) => state.bookingInfoList,

    // for google analytics only
    GET_AGENCY_OWNER_NAME: (state) => state.agencyOwnerName,
    GET_AFFILIATION: (state) => (state.marketerId !== '' && 'MARKETER') || (state.partnerId !== '' && 'PARTNER') || 'intweb',

    // for RLT page
    GET_IS_RLT_PAGE: (state, getter, rootState) => !getter.GET_IS_EMALON_MODE || (getter.GET_IS_EMALON_MODE && rootState.language === 'he'),

    // for direct payment state
    GET_DIRECT_PAYMENT_STATE: (state) => state.directPaymentState || window.localStorage.getItem('direct-payment') === 'true',
    GET_PAYMENT_ONLY_RES: (state) => state.paymentOnlyResponse,
  },
  mutations: {
    SET_AGENCY_AUTHORIZED: (state, payload) => {
      state.agencyAuthorized = payload;
    },
    SET_AGENCY_ERROR_MESSAGE: (state, payload) => {
      state.agencyErrorMessage = payload;
    },
    SET_AGENCY_PHONE_NUMBER: (state, payload) => {
      state.agencyUserPhoneNumber = payload;
    },
    SET_AGENCY_SID: (state, payload) => {
      state.userAgencySid = payload;
    },
    SET_AGENCY_USER: (state, payload) => {
      state.agencyUser = payload;
    },
    SET_AGENCY_VERIFY_CODE: (state, payload) => {
      state.agencyUserVerifyCode = payload;
    },
    SET_IS_AGENCY_AUTHORING: (state, payload) => {
      state.isAgencyAuthoring = payload;
    },
    SET_AGENCY_LOGIN_STATE: (state, payload) => {
      state.isAgencyLoginState = payload;
    },

    // marketer part. Moved from main store.
    SET_MARKETER_ID: (state, payload) => {
      state.marketerId = payload === undefined ? '' : payload;
    },
    SET_AGENCY_FROM_MARKETER_ID: (state, payload) => {
      state.marketerAgency = payload === undefined ? '' : payload;
    },
    SET_AGENCY_CONTENT_MARKETER_ID: (state, payload) => {
      state.marketerAgencyContent = payload || null;
      state.bypassPayment = payload?.bypassPayment || false;
    },
    SET_ODYSSEY_BYPASS_PAYMENT: (state, payload) => {
      state.bypassPayment = payload;
    },
    SET_SUB_AGENCY: (state, payload) => {
      state.subAgency = payload === undefined ? '' : payload;
    },

    // Odyssey agent part
    SET_ODYSSEY_AGENT_CONTENT: (state, payload) => {
      state.odysseyAgentState = payload.forInternalAgentSite;
      state.odysseyAgentContent = payload;
    },
    SET_ODYSSEY_AGENT_CLERK_NAME: (state, payload) => {
      state.odysseyAgentClerkName = payload;
    },
    SET_SELECTED_ODYSSEY_AGENT_CODE: (state, payload) => {
      state.selectedOdysseyAgentCode = payload;
    },
    SET_LOGIN_ODYSSEY_AGENT_CODE: (state, payload) => {
      state.loginOdyAgentCode = payload;
    },
    SET_ALL_AGENT_LIST: (state, payload) => {
      state.allAgentList = payload;
    },

    SET_BOOKING_INFO_LIST: (state, payload) => {
      state.bookingInfoList = payload;
    },
    SET_LOADING_BOOKING_INFO: (state, payload) => {
      state.loadingBookingInfo = payload;
    },

    SET_AGENCY_OWNER_NAME: (state, payload) => {
      state.agencyOwnerName = payload;
      state.isEmalonMode = payload?.toUpperCase() === 'EMALON' ?? false;
    },

    // partner
    SET_PARTNER_ID: (state, payload) => {
      state.partnerId = payload === undefined ? '' : payload;
    },
    SET_DIRECT_PAYMENT_STATE: (state, payload) => {
      state.directPaymentState = payload;
    },
    SET_PAYMENT_ONLY_RES: (state, payload) => {
      state.paymentOnlyResponse = payload;
    },
  },
  actions: {
    REQUEST_AGENCY_LOGIN: async (context, payload) => {
      const data = new FormData();
      const { marketerId, odysseyAgentState, odysseyAgentContent } = context.state;

      if (odysseyAgentState) {
        data.append('odyAgentCode', payload.odyAgentCode);
        data.append('clerkName', payload.clerkName);
        data.append('password', payload.password);
        data.append('operation', 1);
      } else {
        data.append('agencyCode', marketerId);
      }
      context.commit('SET_IS_AGENCY_AUTHORING', true);
      const requestUrl = (odysseyAgentState) ? '/agent/login' : '/agency/requestLogin';

      try {
        const response = await post('BFF', requestUrl, data, context, '', true);
        if (response.data.error) {
          context.commit('SET_AGENCY_ERROR_MESSAGE', response.data.error.message);
        } else {
          context.commit('SET_AGENCY_ERROR_MESSAGE', null);
          context.commit('SET_AGENCY_SID', response.data.data.agencySid);

          if (odysseyAgentState) {
            context.commit('SET_AGENCY_LOGIN_STATE', false);
            context.commit('SET_AGENCY_ERROR_MESSAGE', null);
            context.commit('SET_AGENCY_AUTHORIZED', true);

            window.sessionStorage.setItem('agencySid', context.state.userAgencySid);
            window.sessionStorage.setItem('agencyLogin', true);
            window.sessionStorage.setItem('agencyUser', utf8ToB64(JSON.stringify(odysseyAgentContent)));
          }
        }
      } catch (error) {
        context.commit('SET_AGENCY_ERROR_MESSAGE', error);
      }
      context.commit('SET_IS_AGENCY_AUTHORING', false);
    },
    REQUEST_AGENCY_BY_VERIFY_CODE: async (context) => {
      const data = new FormData();
      const { agencyUserVerifyCode, marketerAgencyContent } = context.state;
      data.append('verificationCode', agencyUserVerifyCode);
      context.commit('SET_IS_AGENCY_AUTHORING', true);
      try {
        const response = await post('BFF', '/agency/verifyCode', data, context, '', true);
        if (response.data.error) {
          context.commit('SET_AGENCY_ERROR_MESSAGE', response.data.error.message);
          context.commit('SET_AGENCY_SID', null);
        } else {
          context.commit('SET_AGENCY_LOGIN_STATE', false);
          context.commit('SET_AGENCY_ERROR_MESSAGE', null);
          context.commit('SET_AGENCY_AUTHORIZED', true);
          context.commit('SET_AGENCY_USER', marketerAgencyContent);

          window.sessionStorage.setItem('agencySid', context.state.userAgencySid);
          window.sessionStorage.setItem('agencyLogin', true);
          window.sessionStorage.setItem('agencyUser', utf8ToB64(JSON.stringify(marketerAgencyContent)));
        }
      } catch (error) {
        context.commit('SET_AGENCY_ERROR_MESSAGE', error);
      }
      context.commit('SET_IS_AGENCY_AUTHORING', false);
    },
    REQUEST_AGENCY_LOGOUT: async (context) => {
      const { marketerId, odysseyAgentState } = context.state;

      context.commit('SET_IS_AGENCY_AUTHORING', true);
      const requestUrl = (odysseyAgentState) ? '/agent/logout' : `/agency/logout?agency=${marketerId}`;

      try {
        const response = await post('BFF', requestUrl, null, context);
        if (response.data.error) {
          context.commit('SET_AGENCY_ERROR_MESSAGE', response.data.error.message);
        } else {
          context.commit('SET_AGENCY_ERROR_MESSAGE', null);
          context.commit('SET_AGENCY_AUTHORIZED', false);
          context.commit('SET_AGENCY_SID', null);
          context.commit('SET_AGENCY_USER', null);

          context.commit('SET_AGENCY_LOGIN_STATE', true);

          window.sessionStorage.removeItem('agencySid');
          window.sessionStorage.removeItem('agencyLogin');
          window.sessionStorage.removeItem('agencyUser');
        }
      } catch (error) {
        context.commit('SET_AGENCY_ERROR_MESSAGE', error);
      }
      context.commit('SET_IS_AGENCY_AUTHORING', false);
    },
    VERIFY_AGENCY_LOGIN_STATE: async (context) => {
      const { marketerId, odysseyAgentState } = context.state;
      const agencySid = window.sessionStorage.getItem('agencySid');
      const requestUrl = (odysseyAgentState) ? '/agent/checkSession' : `/agency/checkSession?agency=${marketerId}`;

      if (agencySid) {
        await context.commit('SET_AGENCY_SID', agencySid);
        try {
          const response = await get('BFF', requestUrl, context);

          if (response.data.error) {
            context.commit('SET_AGENCY_ERROR_MESSAGE', response.data.error.message);
            context.commit('SET_AGENCY_LOGIN_STATE', true);
          } else if (response.data.data.logined) {
            context.commit('SET_AGENCY_LOGIN_STATE', false);
            context.commit('SET_ODYSSEY_AGENT_CLERK_NAME', response.data.data.clerkName);
            context.commit('SET_LOGIN_ODYSSEY_AGENT_CODE', response.data.data.loginOdyAgentCode);
            context.commit('SET_AGENCY_AUTHORIZED', true);
            context.commit('SET_AGENCY_USER', JSON.parse(b64ToUtf8(window.sessionStorage.getItem('agencyUser'))));
          }
        } catch (error) {
          context.commit('SET_AGENCY_ERROR_MESSAGE', null);
          context.commit('SET_AGENCY_AUTHORIZED', false);
          context.commit('SET_AGENCY_SID', null);
          context.commit('SET_AGENCY_USER', null);

          window.sessionStorage.removeItem('agencySid');
          window.sessionStorage.removeItem('agencyLogin');
          window.sessionStorage.removeItem('agencyUser');
          context.commit('SET_AGENCY_LOGIN_STATE', true);
        }
      } else if (odysseyAgentState) {
        context.commit('SET_AGENCY_LOGIN_STATE', true);
      }
    },

    // marketer part. Moved from main store.
    FETCH_MARKETER_ID: async (context, payload) => {
      try {
        const response = await get('DOMESTIC', `/operation/marketerId?dealType=${payload}`, context);
        context.commit('SET_MARKETER_ID', response.data);
      } catch (error) {
        context.commit('SET_MARKETER_ID', '');
      }
    },
    FETCH_AGENCY_FROM_MARKETER_ID: async (context) => {
      const { marketerId } = context.state;
      try {
        const response = await get('DOMESTIC', `/dealType/byAgency?agencyCode=${marketerId}`, context);
        context.commit('SET_AGENCY_FROM_MARKETER_ID', response.data[0]);
      } catch (error) {
        context.commit('SET_AGENCY_FROM_MARKETER_ID', '');
      }
    },
    FETCH_AGENCY_CONTENT_FROM_MARKETER_ID: async (context) => {
      const { marketerId, agencyAuthorized } = context.state;
      try {
        const response = await get('BFF', `/agencyContents/byAgencyCode/${marketerId}`, context);
        context.commit('SET_AGENCY_CONTENT_MARKETER_ID', response.data);
        context.commit('SET_AGENCY_OWNER_NAME', response.data.odyAgentCode);

        if (!agencyAuthorized) { context.commit('SET_AGENCY_LOGIN_STATE', response.data.isB2BMarketer); }
      } catch (error) {
        context.commit('SET_AGENCY_CONTENT_MARKETER_ID', null);
      }
    },
    FETCH_PARTNER_CONTENT_FROM_PARTNER_ID: async (context) => {
      const { partnerId } = context.state;
      try {
        const response = await get('BFF', `/user/${partnerId}/username`, context);
        context.commit('SET_AGENCY_OWNER_NAME', response.data.data.username);
      } catch (error) {
        context.commit('SET_AGENCY_OWNER_NAME', '');
      }
    },
    FETCH_ALL_AGENT_LIST: async (context) => {
      try {
        const response = await get('BFF', '/agent/all', context);
        context.commit('SET_ALL_AGENT_LIST', response.data);
        return response;
      } catch (e) {
        return e;
      }
    },

    FETCH_BOOKING_INFO_LIST: async (context) => {
      const { agencyAuthorized } = context.state;
      context.commit('SET_LOADING_BOOKING_INFO', true);
      try {
        const response = await get('DOMESTIC', '/operation/agentBook', context);
        context.commit('SET_LOADING_BOOKING_INFO', false);
        context.commit('SET_BOOKING_INFO_LIST', response.data);
        return response;
      } catch (e) {
        if (agencyAuthorized && e.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        return e;
      }
    },
    FETCH_BOOKING_INFO_FOR_DIRECT_PAYMENT: async (context, payload) => {
      const { bookingTransId } = payload;
      context.commit('SET_LOADING_STATE', true);
      try {
        const response = await get('DOMESTIC', `/analysis/bookings/${bookingTransId}`, context);

        context.commit('SET_LOADING_STATE', false);
        return response.data;
      } catch (error) {
        console.log(error);
        context.commit('SET_LOADING_STATE', false);
        return null;
      }
    },
    SEND_PAYMENT_ONLY: async (context, payload) => {
      context.commit('SET_LOADING_STATE', true);
      const { bookingTransId, body, amount } = payload;
      try {
        const response = await post('DOMESTIC', `/operation/paymentOnly/${bookingTransId}/?debitAmountNis=${amount}`, body, context);

        window.localStorage.setItem('direct-payment', 'true');

        context.commit('SET_DIRECT_PAYMENT_STATE', true);
        context.commit('SET_PAYMENT_ONLY_RES', {
          success: true,
          url: response.data,
        });
        context.commit('SET_LOADING_STATE', false);
        return response;
      } catch (e) {
        context.commit('SET_LOADING_STATE', false);
        return e;
      }
    },
  },
};
