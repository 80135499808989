import { get, post, utf8ToB64, b64ToUtf8 } from '@/utils/api';

export default {
  state: () => ({
    authorized: false,
    isAuthoring: false,
    memberUser: null,
    userPhoneNumber: null,
    userMemberSid: null,
    userVerifyCode: null,
    errorMessage: null,
    discountPercent: 0,
  }),
  getters: {
    IS_AUTHORIZED: (state) => state.authorized,
    GET_ERROR_MESSAGE: (state) => state.errorMessage,
    GET_IS_AUTHORING: (state) => state.isAuthoring,
    GET_MEMBER_SID: (state) => state.userMemberSid,
    GET_MEMBER: (state) => state.memberUser,
    GET_DISCOUNT_PERCENT: (state) => state.discountPercent,
  },
  mutations: {
    SET_AUTHORIZED: (state, payload) => {
      state.authorized = payload;
    },
    SET_ERROR_MESSAGE: (state, payload) => {
      state.errorMessage = payload;
    },
    SET_PHONE_NUMBER: (state, payload) => {
      state.userPhoneNumber = payload;
    },
    SET_MEMBER_SID: (state, payload) => {
      state.userMemberSid = payload;
    },
    SET_MEMBER: (state, payload) => {
      state.memberUser = payload;
    },
    SET_VERIFY_CODE: (state, payload) => {
      state.userVerifyCode = payload;
    },
    SET_IS_AUTHORING: (state, payload) => {
      state.isAuthoring = payload;
    },
    SET_DISCOUNT_PERCENT: (state, payload) => {
      state.discountPercent = payload;
    },
  },
  actions: {
    REQUEST_BY_PHONE_NUMBER: async (context) => {
      const data = new FormData();
      data.append('phone', context.state.userPhoneNumber);
      context.commit('SET_IS_AUTHORING', true);
      try {
        const response = await post('DOMESTIC', '/clubMember/requestLogin', data, context, '', true);
        if (response.data.error) {
          context.commit('SET_ERROR_MESSAGE', response.data.error.message);
        } else {
          context.commit('SET_ERROR_MESSAGE', null);
          context.commit('SET_MEMBER_SID', response.data.data.memberSid);
        }
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error);
      }
      context.commit('SET_IS_AUTHORING', false);
    },
    REQUEST_BY_VERIFY_CODE: async (context) => {
      const data = new FormData();
      data.append('verificationCode', context.state.userVerifyCode);
      context.commit('SET_IS_AUTHORING', true);
      try {
        const response = await post('DOMESTIC', '/clubMember/verifyCode', data, context, '', true);
        if (response.data.error) {
          context.commit('SET_ERROR_MESSAGE', response.data.error.message);
        } else {
          context.commit('SET_ERROR_MESSAGE', null);
          context.commit('SET_AUTHORIZED', true);
          context.commit('SET_MEMBER', response.data.data.member);
          context.commit('SET_DISCOUNT_PERCENT', response.data.data.discountPercent);

          window.sessionStorage.setItem('memberSid', context.state.userMemberSid);
          window.sessionStorage.setItem('memberLogin', true);
          window.sessionStorage.setItem('memberUser', utf8ToB64(JSON.stringify(response.data.data.member)));
        }
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error);
      }
      context.commit('SET_IS_AUTHORING', false);
    },
    REQUEST_LOGOUT: async (context) => {
      context.commit('SET_IS_AUTHORING', true);
      try {
        const response = await post('DOMESTIC', '/clubMember/logout', null, context);
        if (response.data.error) {
          context.commit('SET_ERROR_MESSAGE', response.data.error.message);
        } else {
          context.commit('SET_ERROR_MESSAGE', null);
          context.commit('SET_AUTHORIZED', false);
          context.commit('SET_MEMBER_SID', null);
          context.commit('SET_MEMBER', null);
          context.commit('SET_DISCOUNT_PERCENT', 0);

          window.sessionStorage.removeItem('memberSid');
          window.sessionStorage.removeItem('memberLogin');
          window.sessionStorage.removeItem('memberUser');
        }
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error);
      }
      context.commit('SET_IS_AUTHORING', false);
    },
    VERIFY_LOGIN_STATE: async (context) => {
      const memberSid = window.sessionStorage.getItem('memberSid');
      const productId = JSON.parse(window.localStorage.getItem('roomList'))?.roomList[0]?.packSelectionId || '';
      if (memberSid) {
        await context.commit('SET_MEMBER_SID', memberSid);
        try {
          const response = await get('DOMESTIC', `/clubMember/checkSession?productId=${productId}`, context);
          if (response?.data?.data?.logined) {
            context.commit('SET_DISCOUNT_PERCENT', response.data.data.discountPercent);
          }

          if (response.data.error) {
            context.commit('SET_ERROR_MESSAGE', response.data.error.message);
          } else if (response.data.data.logined) {
            context.commit('SET_AUTHORIZED', window.sessionStorage.getItem('memberLogin'));
            context.commit('SET_MEMBER', JSON.parse(b64ToUtf8(window.sessionStorage.getItem('memberUser'))));
          } else {
            window.sessionStorage.removeItem('memberSid');
            window.sessionStorage.removeItem('memberLogin');
            window.sessionStorage.removeItem('memberUser');
          }
        } catch (error) {
          context.commit('SET_ERROR_MESSAGE', error);
        }
      }
    },
    REQUEST_CLUB_MEMBER_REGISTER: async (context, payload) => {
      context.commit('SET_IS_AUTHORING', true);
      try {
        const response = await post('DOMESTIC', '/clubMember', payload, context, '', false);
        context.commit('SET_IS_AUTHORING', false);
        if (response.data.error) {
          context.commit('SET_ERROR_MESSAGE', response.data.error.message);
          return false;
        } else {
          return true;
        }
      } catch (error) {
        context.commit('SET_IS_AUTHORING', false);
        context.commit('SET_ERROR_MESSAGE', error);
      }
      return false;
    },
  },
};
